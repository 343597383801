.google-maps-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;  /* adjust as needed */
    margin: 0 auto;
  }
  
  .map-container {
    width: 100%;
    height: 480px;  /* adjust height as needed */
  }
  
  .info-and-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .route-info {
    display: flex;
    gap: 20px;
  }
  
  .route-form {
    display: flex;
    gap: 10px;
  }
  
  .route-form input {
    padding: 5px 10px;
  }
  
  .route-form button {
    padding: 5px 10px;
    cursor: pointer;
  }